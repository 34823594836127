<template>
  <section class="invoice-preview-wrapper">
    <b-row
        v-if="outgoingInvoice"
        class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
          cols="12"
          xl="9"
          md="8"
      >
        <b-card
            v-if="$route.name == 'OutgoingInvoice edit'"
            no-body
            class="invoice-preview-card position-relative"
        >
          <validation-observer #default="{handleSubmit}">
            <b-form @submit.prevent="handleSubmit(submitOutgoingInvoice)">
              <form-outgoing-invoice
                  :invoice="outgoingInvoice"
              />
              <button ref="submitOutgoingInvoiceFormRef"
                      class="d-none"></button>
            </b-form>
          </validation-observer>

        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
          cols="12"
          md="4"
          xl="3"
          class="offer-actions"
      >

        <!-- Right Col: Card -->
        <buttons-col
            :outgoing-invoice="outgoingInvoice"
            @submitForm="submitForm"
        />
      </b-col>

    </b-row>

  </section>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useOutgoingInvoices } from './useOutgoingInvoices'
import { ValidationObserver } from 'vee-validate'

import useAPI from '@/utils/useAPI'
import store from '@/store'
import router from '@/router'
import FormOutgoingInvoice from '@/components/form/outgoingInvoice/OutgoingInvoice'
import ButtonsCol from './components/_buttons_col'

export default {
  components: {
    ValidationObserver,
    FormOutgoingInvoice,
    ButtonsCol
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const submitOutgoingInvoiceFormRef = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const outgoingInvoice = computed(() => {
      return store.getters['outgoingInvoice/getOutgoingInvoice'](router.history.current.params.outgoingInvoiceId)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchArticles,
      fetchUsers,
      fetchCompanies,
      fetchOutgoingInvoices,
      fetchPaymentMethods,
      fetchPaymentTerms,
      fetchVats
    } = useAPI()

    const { submitValidatedOutgoingInvoice } = useOutgoingInvoices()

    const submitForm = () => {
      submitOutgoingInvoiceFormRef.value.click()
    }

    const submitOutgoingInvoice = () => {
      submitValidatedOutgoingInvoice(outgoingInvoice.value)
          .then(response => {
            router.push({ name: 'OutgoingInvoice view', params: { outgoingInvoiceId: outgoingInvoice.value.id } })
          })

    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchArticles()
    fetchUsers()
    fetchCompanies()
    fetchOutgoingInvoices()
    fetchPaymentMethods()
    fetchPaymentTerms()
    fetchVats()

    if (outgoingInvoice.value && outgoingInvoice.value.billNumber != null) {
      router.push({ name: 'OutgoingInvoice view', params: { outgoingInvoiceId: outgoingInvoice.value.id } })
    }

    return {
      // Components

      // Data
      submitOutgoingInvoiceFormRef,

      // Computed
      outgoingInvoice,

      // Methods
      submitForm,
      submitOutgoingInvoice
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>